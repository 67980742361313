import * as storage from "../storage";

export interface Session {
  token: string;
  name: string;
}

export function createSession() {
  return {
    token: null,
    name: null,
    ...storage.getSession()
  } as Session;
}
