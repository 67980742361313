import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TranslatorService} from '../core/translator/translator.service';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';

import {menu} from './menu';
import {LayoutComponent} from '../layout/layout.component';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard} from './auth/auth-guard';
import {AuthModule} from './auth/auth.module';

const routes: Routes = [

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'events', pathMatch: 'full'},
      {path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
      {path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule)}
    ]
  },
  {path: 'login', component: LoginComponent},


  // Not found
  {path: '**', redirectTo: 'home'}

];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    AuthModule,
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    menuService.addMenu(menu);
  }
}
