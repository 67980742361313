<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" href="#/">
            <div class="brand-logo">
                <img class="img-fluid" src="assets/img/logo.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/logo-single.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav mr-auto flex-row">
        <li class="nav-item">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize="" (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
    <ul class="navbar-nav flex-row">
      <li class="nav-item mr-3">
        <span class="text-white">{{session.name$ | async}}</span>
        <button class="btn btn-primary ml-3" (click)="sessionService.logout()">Logout</button>
      </li>
    </ul>
    <!-- END Right Navbar-->
</nav>
<!-- END Top Navbar-->
