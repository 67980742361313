const Home = {
  text: 'Home',
  link: '/home',
  icon: 'icon-home'
};

const headingMain = {
  text: 'Main Navigation',
  heading: true
};

export const menu = [
  headingMain,
  Home,
  {
    text: 'Events',
    link: '/events',
    icon: 'icon-eye'
  }
];
