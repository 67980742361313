import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {SessionQuery} from './state/session.query';
import {catchError, switchMap} from 'rxjs/operators';
import {SessionService} from './state/session.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private session: SessionQuery, private sessionService: SessionService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.session.isLoggedIn() && request.url.match(/\/admin\//)) {
      return this.session.token$.pipe(
        switchMap(token => {
          // console.log('token: ', token);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            }
          });
          return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
              this.sessionService.logout();
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
          }));
        })
      );
    }
    return next.handle(request);
  }
}
