import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ngx-custom-validators';
import {SettingsService} from '../../../core/settings/settings.service';
import {SessionService} from '../state/session.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  valForm: FormGroup;
  loginFailed = false;

  constructor(public settings: SettingsService, fb: FormBuilder, private authService: SessionService, private router: Router) {
    this.valForm = fb.group({
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
      password: [null, Validators.required]
    });
  }

  submitForm($ev, value: any) {
    $ev.preventDefault();
    Object.keys(this.valForm.controls).forEach(c => this.valForm.controls[c].markAsTouched());
    if (this.valForm.valid) {
      console.log('Valid!');
      console.log(value);
      this.loginFailed = false;
      this.authService.login(value).subscribe(() => {
        this.router.navigateByUrl('');
      }, err => {
        this.loginFailed = true;
      });
    }
  }

  ngOnInit(): void {
  }
}
