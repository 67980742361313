import {Injectable} from '@angular/core';
import {SessionStore} from './session.store';
import {SessionDataService} from './session-data.service';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(private authStore: SessionStore, private authDataService: SessionDataService, private router: Router) { }

  login(creds) {
    return this.authDataService.login(creds).pipe(tap((session) => this.authStore.login(session)));
  }

  logout() {
    this.authStore.logout();
    this.router.navigate(['/login']);
  }
}
