import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import {map, mapTo} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {
  constructor(private http: HttpClient) {}

  login(creds) {
    const url = `${environment.apiUrl}/admin/login`;
    return this.http.post(url, creds);
  }
}
